import { createSlice } from '@reduxjs/toolkit'
const queryString = require('query-string');

const query = queryString.parse(window.location.search);
const initialState = {
    zipcode: query?.zipcode || 10001,
    activeTrim: {},
    activePurchaseMethod: "lease",
    annualMileage: 10000,
    equivalentGasMPG: 30,
    electricityPrice: .45,
    fuelPrice: 3.25,
    percentElectric: 50,
    numYears: 5,
    trims: [],
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateZipcode: (state, action) => {
            state.zipcode = action.payload
        },
        updateActiveTrim: (state, action) => {
            state.activeTrim = action.payload
        },
        updateActivePurchaseMethod: (state, action) => {
              state.activePurchaseMethod = action.payload
        },
        updateAnnualMileage: (state, action) => {
            state.annualMileage = action.payload
        },
        updateEquivalentGasMPG: (state, action) => {
            state.equivalentGasMPG = action.payload
        },
        updateElectricityPrice: (state, action) => {
            state.electricityPrice = action.payload
        },
        updateFuelPrice:(state, action) => {
            state.fuelPrice = action.payload
        },
        updatePercentElectric: (state, action) => {
            state.percentElectric = action.payload
        },
        updateTrims: (state, action) => {
            state.trims = action.payload
        },
    }
})

export const { 
    updateZipcode,
    updateActiveTrim,
    updateActivePurchaseMethod,
    updateAnnualMileage,
    updateEquivalentGasMPG,
    updateElectricityPrice,
    updateFuelPrice,
    updatePercentElectric,
    updateTrims,
    updateMaintenance,
} = userSlice.actions

export default userSlice.reducer
