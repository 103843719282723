import TabTitle from "../components/TabTitle";
import SelectTrim from "../components/Inputs/Select/SelectTrim";
// import SelectPurchaseMethod from "../components/Inputs/Select/SelectPurchaseMethod";
import IncentiveCard from "../components/IncentiveCard/IncentiveCard";
import {
  highestEligibleAmountIncentiveGivenType,
  incentiveSavingsOfGivenTypes,
} from "../functions/incentive/incentiveHelperFunctions";
import Grid from "@mui/material/Grid";
import TextFieldZipcode from "../components/Inputs/TextField/TextFieldZipcode";
import TabStructure from "../components/TabStructure";
import { FormatAsDollars } from "../utils/Format";
import { useSelector } from "react-redux";
import { getTooltips } from "../redux/selectors";
import StateNameLookup from "../utils/StateNameLookup";

const Incentives = ({ vehicle, vehicleIncentives, zipcode }) => {
  const state = StateNameLookup({ zipcode });
  const tooltip = useSelector(getTooltips)
  const highestStateIncentive = highestEligibleAmountIncentiveGivenType(
    vehicleIncentives,
    "state"
  );
  const highestUtilityIncentive = highestEligibleAmountIncentiveGivenType(
    vehicleIncentives,
    "Power Supplier"
  );

  const federalStateUtilityIncentiveSavings = FormatAsDollars(
    incentiveSavingsOfGivenTypes(vehicleIncentives, [
      "Country",
      "state",
      "power supplier",
    ])
  );

  const incentiveList = [
    {
      grantorType: "State",
      amount: highestStateIncentive
        ? highestStateIncentive?.evaluation?.amount_in_purchase
        : 0,
      description: highestStateIncentive
        ? `Max potential incentives from ${state}`
        : `State incentives are not available at this time`,
      link: highestStateIncentive?.details_url,
      name: highestStateIncentive?.name,
    },
    {
      grantorType: "Utility",
      amount: highestUtilityIncentive
        ? highestUtilityIncentive?.evaluation?.amount_in_purchase
        : 0,
      description: highestUtilityIncentive
        ? `Max potential incentives available in your area`
        : `Utility incentives are not available at this time`,
      link: highestUtilityIncentive?.details_url,
      name: highestUtilityIncentive?.name,
    },
  ];

  const renderIncentives = incentiveList.map((incentive) => {
    return (
      <IncentiveCard
        key={incentive.grantorType}
        grantor={incentive.grantorType}
        amount={FormatAsDollars(incentive.amount)}
        description={incentive.description}
        link={incentive.link}
      />
    );
  });


  const getSubheading = () => {
       if (!vehicle.model) {
         return `Update your ZIP code to see everything you may qualify for just by owning a ${vehicle.model_year} ${vehicle.model}.`;
       }
 
       if (vehicle.model.includes("SANTA")) {
         return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the SANTA FE Plug-in Hybrid.";
       }
 
       if (vehicle.model.includes("KONA")) {
         return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like KONA Electric.";
       }
 
       if (vehicle.model.includes("TUCSON")) {
         return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the TUCSON Plug-in Hybrid.";
       }
 
       if (vehicle.model.includes("IONIQ Plug-In Hybrid")) {
         return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the IONIQ Plug-in Hybrid.";
       }
 
       if (vehicle.model.includes("IONIQ 5")) {
         return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like the IONIQ 5.";
       }

       if (vehicle.model.includes("IONIQ 6")) {
          return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like the IONIQ 6.";
       }
     };

  const left = (
    <TabTitle
      header={`${federalStateUtilityIncentiveSavings} in potential tax<br/> credits and incentives.`}
      subHeader={getSubheading()}
      tooltip={tooltip.incentivesTip}
      tooltipPlacement="left"
    />
  );

  const right = (
    <Grid
      container
      rowSpacing={2}
      style={{ maxWidth: "300px", margin: "0 auto" }}
      className="form-fields-grid"
    >
      <Grid item xs={12}>
        <TextFieldZipcode />
      </Grid>
      <Grid item xs={12}>
        <SelectTrim />
      </Grid>
      {/* <Grid item xs={12}>
        <SelectPurchaseMethod />
      </Grid> */}
    </Grid>
  );

  return <TabStructure left={left} middle={renderIncentives} right={right} />;
};

export default Incentives;
