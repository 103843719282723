// Miles Driven - From User Input
// percentElectric - Pending Client
// kWh_per_100_miles - electric_efficiency from Vehicles Endpoint
// lbCo2_per_kwh - See location endpoint, grid pollution and power mix.
// lbCO2_per_gallon_of_gas = 18.9 Source
// Ev_mpg - fossil_fuel_efficiency from vehicles Endpoint(Only when hybrids are in -scope)
// Equivalent_gas_car_mpg - from vehicles endpoint (equivalent_gas_vehicle[fossil_fuel_efficiency])
// Percentage - (total gas emissions - electric emissions) / total gas emissions  * 100
import { isPHEV } from "../vehicleHelper";

const calcEmissions = {
    electricEmitted: function (miles, electricEfficiency, electricityEmissions) {
        // electricity emissions is given in g/kwh and we need to return in lbs
        return miles * electricEfficiency / 100 * electricityEmissions * .0022
    },

    gasEmitted: function (miles, gasolineEfficiency, gasolineEmissions) {
        if (!gasolineEfficiency) return 0
        return Math.round(miles / gasolineEfficiency * gasolineEmissions);
    },

    hybridAdjustment: function (vehicle, percentElectric) {
        return isPHEV(vehicle) ? percentElectric : 1
    },

    evEmitted: function (miles, electricEfficiency, electricityEmissions, gasolineEfficiency, gasolineEmissions, ev, percentElectric) {
        percentElectric = Number(percentElectric) / 100
        const fuelAdjustment = this.hybridAdjustment(ev, percentElectric)

        return Math.round(fuelAdjustment * this.electricEmitted(miles, electricEfficiency, electricityEmissions) + (1 - fuelAdjustment) * this.gasEmitted(miles, gasolineEfficiency, gasolineEmissions))
    },

};

export default calcEmissions;