import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import CustomizedToolTip from "../CustomizedToolTip/CustomizedToolTip"


const TextField = ( {title, defaultValue, onChange, maxLength, tooltip, tooltipPlacement, className} ) => {

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          position: 'relative',
          backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #e6e6e6',
          fontSize: 16,
          fontWeight: 400,
          width: '100%',
          padding: '10px 12px',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ])
        },
      }));

      const keyDown = (e) => {
        if (e.key === 'Enter') {
          onChange(e.target.value);
        }
      }

    return (
        <FormControl className={className} sx={{width: "100%"}} variant="standard" onSubmit={onChange}>
            <InputLabel style={{color: "#666666"}} sx={{fontSize: "14px", fontWeight: 700, transform: "translate(0, -8px) scale(1)"}} htmlFor={`${title}-input`}>
              {title}
              {tooltip && <CustomizedToolTip title={tooltip} placement={tooltipPlacement}/>}
            </InputLabel>
            <BootstrapInput
                defaultValue={defaultValue} 
                id={`${title}-input`}
                onBlur={(e) => onChange(e.target.value)}
                onKeyDown={(e) => keyDown(e)}
                inputProps={{ maxLength: maxLength }}
            />
        </FormControl>
    )
}

export default TextField
