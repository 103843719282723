import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const BarGraph = ({
  header,
  graphData,
  labels,
  backgroundColor = ["#000000", "#00AAD2"],
}) => {
  const options = {
    layout: {
      padding: {
        top: 20,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          borderColor: "green",
          borderWidth: 5,
          display: false,
        },
        ticks: {
          display: false,
          font: {
            family: "HyundaiSansText",
            size: "13px",
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000000",
          font: {
            family: "HyundaiSansHead-Bold",
            size: "13px",
          },
        },
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        font: {
          size: 18,
          family: "HyundaiSansText",
          weight: "bold",
        },
        color: "#000",
        display: true,
        anchor: "end",
        align: "end",
        offset: 0,
        useHtml: true,
        formatter: function (value, ctx) {
          return Number(value.toFixed(0)).toLocaleString("en-US") + " lbs. CO₂";
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.formattedValue;
            return label + " lbs. CO₂";
          },
          title: ([{ label }]) => (label ? label.replace(",", " ") : ""),
        },
      },
      // title: {
      //     display: true,
      //     position: "top",
      //     text: header,
      // },
    },
  };

  // Specific Change for Label
  labels.map((label, index) => {
    if (label === "2023 TUCSON Plug-In Hybrid") {
      labels[index] = ["2023 TUCSON", "Plug-In Hybrid"];
    }

    if (label === "2023 KONA Electric") {
      labels[index] = ["2023 KONA", "Electric"];
    }

    if (label === "2023 IONIQ 5") {
      labels[index] = ["2023", "IONIQ 5"];
    }

    if (label === "2023 IONIQ 6") {
      labels[index] = ["2023", "IONIQ 6"];
    }

    if (label === "2022 IONIQ Plug-in Hybrid") {
      labels[index] = ["2022 IONIQ", "Plug-in Hybrid"];
    }

    if (label === "2023 SANTA FE Plug-in Hybrid") {
      labels[index] = ["2023 SANTA FE", "Plug-in Hybrid"];
    }

    return "";
  });

  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: graphData,
        backgroundColor: backgroundColor,
      },
    ],
  };

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ height: "500px" }}>
        <Bar options={options} data={data} plugins={[ChartDataLabels]} />
      </Grid>
    </Grid>
  );
};
export default BarGraph;

BarGraph.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  graphData: PropTypes.array,
  labels: PropTypes.array,
  backgroundColors: PropTypes.array,
};
