import { useEffect, useState } from "react";
import {
  updateZipcode,
  updateTrims,
  updateActiveTrim,
} from "../redux/Slices/userSlice";
import { getZipcode, getActiveTrim } from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useGetVehicleQuery } from "../redux/services/unifiedAPIQuery";

const queryString = require("query-string");

export default function useGetData() {
  const [skip, setSkip] = useState(true);
  const zipcode = useSelector(getZipcode);
  const activeTrim = useSelector(getActiveTrim);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    activeTrim: null,
    vehicleData: null,
    zipcode: null,
  });

  const {
    status: vehicleStatus,
    data: vehicleData,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: zipcode,
    },
    { skip }
  );

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    let trims = [];
    let activeTrim = [];
    if (Array.isArray(query.trims)) {
      query.trims.map((trim) => trims.push(JSON.parse(trim)));
      activeTrim.push(JSON.parse(query.trims[0]));
    } else {
      trims.push(JSON.parse(query.trims));
      activeTrim.push(JSON.parse(query.trims));
    }
    dispatch(updateZipcode(query?.zipcode));
    if (trims) {
      dispatch(updateTrims(trims));
      dispatch(updateActiveTrim(activeTrim[0]));
      setSkip(false);
    }
  }, [dispatch, setSkip]);

  useEffect(() => {
    if (vehicleError) {
      dispatch(updateZipcode(90210));
    }
  }, [vehicleError, dispatch]);

  useEffect(() => {
    if (vehicleStatus !== "fulfilled") return;
    setData({ activeTrim, vehicleData, zipcode });
  }, [vehicleStatus, activeTrim, vehicleData, zipcode]);

  return {
    isLoading: vehicleLoading,
    isError: Boolean(vehicleError),
    error: vehicleError,
    ...data,
  };
}
