
const LBC02_PER_GALLON_OF_GAS = {
    title: "lbCO2_per_gallon_of_gas",
    value: 19.6,
    valueAsFormatted: "19.6 lb/gal",
    link: "https://www.eia.gov/tools/faqs/faq.php?id=307&t=11",
};

export {
    LBC02_PER_GALLON_OF_GAS,
}