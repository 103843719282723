import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useGetLocationQuery } from "../redux/services/unifiedAPIQuery"
import BarGraph from "../components/Graphs/BarGraph/BarGraph"
import calcEmissions from '../functions/vehicle/emissions/calcEmissions';
import { LBC02_PER_GALLON_OF_GAS } from "../data/assumptions/Assumptions"
import SelectTrim from "../components/Inputs/Select/SelectTrim";
import SelectAnnualMileage from "../components/Inputs/Select/SelectAnnualMileage"
import TabTitle from "../components/TabTitle"
import SlideEquivalentGasMPG from "../components/Inputs/Slider/SlideEquivalentGasMPG";
import SlidePercentElectric from "../components/Inputs/Slider/SlidePercentElectric";
import { useSelector } from 'react-redux';
import { updateElectricityPrice, updateFuelPrice } from '../redux/Slices/userSlice';
import { FormatAsTwoDecimals } from '../utils/Format'
import TextFieldZipcode from "../components/Inputs/TextField/TextFieldZipcode"
import Grid from '@mui/material/Grid';
import TabStructure from "../components/TabStructure";
import { FormatAsPercent, FormatAsThousands } from "../utils/Format";
import { isPHEV } from "../functions/vehicle/vehicleHelper";
import { getTooltips } from "../redux/selectors";

const Emissions = ({ vehicle, zipcode, activeTrim }) => {
       const dispatch = useDispatch()
       const { data: locationData } = useGetLocationQuery({
              postcode: zipcode
       })

       useEffect(() => {
              if (locationData) {
                const electricityPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].electricity / 100)
                const fuelPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].gasoline)
                dispatch(updateElectricityPrice(electricityPrice))
                dispatch(updateFuelPrice(fuelPrice))
              }
       }, [locationData, dispatch])

    const { annualMileage, equivalentGasMPG, percentElectric, numYears } = useSelector(state => state.user)

    const gasEmission = calcEmissions.gasEmitted(annualMileage, equivalentGasMPG, LBC02_PER_GALLON_OF_GAS.value) * numYears

    let evEmission = 0;
    if(locationData) {
       evEmission = calcEmissions.evEmitted(annualMileage, vehicle.electric_efficiency, locationData.location.regional_electricity[0].emissions.emissions_co2, vehicle.fossil_fuel_efficiency, LBC02_PER_GALLON_OF_GAS.value, vehicle, percentElectric) * numYears
    }

    const graphData = [gasEmission, evEmission]
    const labels = [['Gas-only', 'vehicle'], `${vehicle.model_year} ${vehicle.model}`]
    const backgroundColor = ['#000000', '#00AAD2']

    const inputs = [
        <TextFieldZipcode />,
        <SelectTrim />,
        <SelectAnnualMileage />,
        <SlideEquivalentGasMPG type={vehicle.fuel} />,
    ]

    isPHEV(vehicle) && inputs.push(<SlidePercentElectric />)
    const tooltip = useSelector(getTooltips)

    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name} item xs={12}>
                    {input}
                </Grid>
            )
        })
    }

    const carbonSavings = gasEmission - evEmission
    const carbonPercentage = FormatAsPercent(carbonSavings / gasEmission * 100)

    const left = <TabTitle
        header={`Reduce your emissions from<br/> driving by up to ${carbonPercentage}.`}
        subHeader={`Over 5 years, that could keep up to ${FormatAsThousands(carbonSavings)} lbs. of CO${String.fromCodePoint(8322)} out of our atmosphere`}
        tooltip={tooltip.emissionsTip}
        tooltipPlacement="left"
    />

    const middle = <BarGraph
        header={'Estimated carbon emissions from driving over 5 years'} graphData={graphData}
        labels={labels}
        backgroundColor={backgroundColor}
    />

    const right = <Grid container rowSpacing={2} style={{maxWidth:'300px', margin: '0 auto'}} className="form-fields-grid">
        {renderInputs(inputs)}
    </Grid >

    return (
        <TabStructure
            left={left}
            middle={middle}
            right={right}
        />
    )
}

export default Emissions
