const FormatAsDollars = val => {
  return (
    "$" +
    Math.round(val)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

const FormatAsDollarsPerGal = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(2)
      .toString() +
    "/gal"
  );
};

const FormatAsPercent = val => {
  return parseFloat(val).toFixed(0) + "%";
};

const FormatAsThousands = val => {
  return Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const FormatAsTwoDecimals = val => {
  return parseFloat(val)
    .toFixed(2)
};

export {
  FormatAsDollars,
  FormatAsDollarsPerGal,
  FormatAsPercent,
  FormatAsThousands,
  FormatAsTwoDecimals,
}
