import DoughnutGraph from "../components/Graphs/DoughnutGraph/DoughnutGraph"
import { useSelector } from 'react-redux';
import SelectTrim from "../components/Inputs/Select/SelectTrim";
import SelectAnnualMileage from "../components/Inputs/Select/SelectAnnualMileage"
import TabTitle from "../components/TabTitle"
import SlideEquivalentGasMPG from "../components/Inputs/Slider/SlideEquivalentGasMPG";
import SlideElectricityPrice from "../components/Inputs/Slider/SlideElectricityPrice";
import SlideFuelPrice from "../components/Inputs/Slider/SlideFuelPrice";
import SlidePercentElectric from "../components/Inputs/Slider/SlidePercentElectric";
import FuelCost from "../functions/vehicle/costOfOwnership/FuelCost";
import { incentiveSavingsOfGivenTypes } from "../functions/incentive/incentiveHelperFunctions"
import Grid from '@mui/material/Grid';
import TextFieldZipcode from "../components/Inputs/TextField/TextFieldZipcode"
import TabStructure from "../components/TabStructure";
import { isPHEV } from "../functions/vehicle/vehicleHelper";
import { FormatAsDollars } from "../utils/Format";
import { getTooltips } from "../redux/selectors";

const CostOfOwnership = ({
    vehicle,
    activeTrim,
}) => {
    const { annualMileage, equivalentGasMPG, electricityPrice, fuelPrice, percentElectric, numYears } = useSelector(state => state.user)

    const fuelSavingsData = FuelCost.totalCost(annualMileage,equivalentGasMPG, vehicle, electricityPrice, fuelPrice, percentElectric) * numYears;

    let federalStateUtilityIncentiveSavings = incentiveSavingsOfGivenTypes(vehicle.incentives, ["Country", "state", "power supplier"])
  
    // Array of Incentive, Fuel Savings, Maintenance
    const data = [federalStateUtilityIncentiveSavings, fuelSavingsData]
    const labels = ["Potential incentives", "Potential fuel savings"];
    const backgroundColor = ["#002C5E", "#00AAD2"]

    const inputs = [
        <TextFieldZipcode />,
        <SelectTrim />,
        <SelectAnnualMileage />,
        <SlideEquivalentGasMPG type={vehicle.fuel} />,
        <SlideElectricityPrice />,
        <SlideFuelPrice />,
    ]

    isPHEV(vehicle) && inputs.push(<SlidePercentElectric />)

    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name} item xs={12} style={{maxWidth:'300px', margin: '0 auto'}}>
                    {input}
                </Grid>
            )
        })
    }

    const totalSavings = FormatAsDollars(data.reduce((acc, num) => acc + num, 0))    
    const tooltip = useSelector(getTooltips)


    const left = (
      <TabTitle
        header={`Over 5 years, you could save<br/> as much as ${totalSavings}. `}
        subHeader={`The more you drive, the more you could potentially save.`}
        tooltip={tooltip.costOwnershipTip}
        tooltipPlacement="left"
      />
    );

    const middle = (
      <DoughnutGraph
        header={"Potential savings over 5 years"}
        subHeader={`${totalSavings}`}
        graphData={data}
        labels={labels}
        backgroundColor={backgroundColor}
        vehicle={vehicle}
        activeTrim={activeTrim}
      />
    );

    const right = <Grid container rowSpacing={2} className="form-fields-grid cost-of-ownership-right">
        {renderInputs(inputs)}
    </Grid >

    return (
        <TabStructure 
            left={left} 
            middle={middle} 
            right={right}
        />
    )
}

export default CostOfOwnership
