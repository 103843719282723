import Select from "../Select"
import { useDispatch, useSelector } from 'react-redux';
import { updateAnnualMileage } from "../../../redux/Slices/userSlice";
import { getAnnualMiles, getTooltips } from "../../../redux/selectors"
import GaTracker from "../../../utils/GaTracker";

const SelectAnnualMileage = () => {
    const dispatch = useDispatch()
    const annualMiles = useSelector(getAnnualMiles)
    const tooltip = useSelector(getTooltips)

    const updateAnnualMiles = (miles) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Annual Miles",
          label: "Updated Annual Miles Input",
        });

        dispatch(updateAnnualMileage(Number(miles)))
    }

    const annualMileageOptions = [
        { value: 10000, label: '10,000' },
        { value: 12000, label: '12,000' },
        { value: 15000, label: '15,000' }
    ]

    return (
        <Select
            title="Annual mileage"
            options={annualMileageOptions}
            value={annualMiles}
            onChange={updateAnnualMiles}
            tooltip={tooltip.mileageTip}
            tooltipPlacement="bottom"
        />
    )
}

export default SelectAnnualMileage
